import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24e873a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "book-cover-placeholder"
}
const _hoisted_3 = { class: "book-info" }
const _hoisted_4 = { id: "title" }
const _hoisted_5 = { id: "author" }
const _hoisted_6 = { class: "progress-bar" }
const _hoisted_7 = { class: "progress-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "book",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openBook(_ctx.book_id)))
  }, [
    (_ctx.base64_img != 'No Image')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.base64ToImg(_ctx.base64_img)
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.author["first-name"]) + " " + _toDisplayString(_ctx.author["last-name"]), 1),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createVNode(_Transition, { name: "progress" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "progress",
              style: _normalizeStyle({ width: _ctx.getBookProgress() + '%' })
            }, null, 4)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getBookProgress()) + "%", 1)
      ], 512), [
        [_vShow, !_ctx.is_local]
      ])
    ])
  ]))
}