
import { defineComponent } from "vue";
import { auth } from "@/main";
export default defineComponent({
  methods: {
    openLogout() {
      if (auth.currentUser) {
        this.$store.state.signout_visible = true;
      }
    },
  },
  computed: {
    img_url(): string {
      if (this.$store.state.user && this.$store.state.user.photoURL) {
        return this.$store.state.user.photoURL;
      } else {
        //get random number
        let random_num = Math.random();
        return `https://api.dicebear.com/5.x/personas/svg?seed=${random_num}&backgroundColor=c0aede`;
      }
    },
  },
  setup() {
    return {};
  },
});
