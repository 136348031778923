
import { defineComponent } from "vue";
import { db, auth } from "@/main";
import { getDoc, collection, doc } from "@firebase/firestore";
export default defineComponent({
  methods: {
    validateFontSize(size: number): number {
      return size > 0 && size < 100 ? size : 18;
    },
    validatePadding(padding: number): number {
      return padding >= 0 && padding < 20 ? padding : 0;
    },
    validateLineHeight(lineHeight: number): number {
      return lineHeight >= 0 && lineHeight <= 5 ? lineHeight : 2;
    },
  },
  computed: {},

  mounted() {
    //load user preferences
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let docRef = doc(collection(db, `users`), user.uid);

        const userDoc = await getDoc(docRef);
        const userData = userDoc.data();
        console.log(userData);

        if (userData) {
          if (
            userData.text_font_size &&
            this.$store.state.text_font_size !== userData.text_font_size
          ) {
            this.$store.commit(
              "setTextFontSize",
              this.validateFontSize(userData.text_font_size)
            );
          }

          if (
            userData.text_padding &&
            this.$store.state.text_padding !== userData.text_padding
          ) {
            this.$store.commit(
              "setTextPadding",
              this.validatePadding(userData.text_padding)
            );
          }

          if (
            userData.text_line_height &&
            this.$store.state.text_line_height !== userData.text_line_height
          ) {
            console.log(
              `setting line height ${
                this.$store.state.text_line_height !== userData.text_line_height
              }`
            );

            this.$store.commit(
              "setTextLineHeight",
              this.validateLineHeight(userData.text_line_height)
            );
          }
        }
      }
    });
  },

  setup() {
    return {};
  },
});
