
import { defineComponent } from "vue";
import { auth, db } from "@/main";

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { setDoc, doc, getDocs, collection } from "firebase/firestore";
import baseHeader from "@/components/base-header.vue";
import addBookModal from "@/components/add-book-modal.vue";
import bookCard from "@/components/book-card.vue";

export default defineComponent({
  name: "HomeView",
  components: { baseHeader, addBookModal, bookCard },
  data() {
    return {
      scroll_position: 0,
      signout_visible: false,
      test_visibility: true,
    };
  },
  methods: {
    async loadLibrary() {
      if (!auth.currentUser) return;

      const querySnapshot = await getDocs(
        collection(db, `users/${auth.currentUser.uid}/books`)
      );

      querySnapshot.forEach((doc) => {
        let temp = doc.data();
        temp.id = doc.id;
        //check if the book is already in the library
        let book = this.$store.state.books.find(
          (book: any) => book.id == temp.id
        );
        if (!book) {
          this.$store.commit("addBook", temp);
        }
      });
    },
    greeting(): string {
      let username = "guest";
      if (auth.currentUser?.displayName) {
        username = auth.currentUser.displayName.split(" ")[0];
        if (!username) {
          username = auth.currentUser.displayName;
        }
      }
      let greetings = {
        morning: [
          "good morning",
          "here's your latte",
          "aloha kakahiaka",
          "bonjour",
          "hi",
        ],
        afternoon: ["good afternoon", "good day", "hi"],
        evening: ["good evening", "hello", "good night", "bonne nuit"],
      };

      //say hello to the user based on local time
      let date = new Date();
      let hour = date.getHours();
      let greeting = "hi";
      // return a random greeting from the appropriate array
      if (hour < 12) {
        greeting =
          greetings.morning[
            Math.floor(Math.random() * greetings.morning.length)
          ];
      } else if (hour < 18) {
        greeting =
          greetings.afternoon[
            Math.floor(Math.random() * greetings.afternoon.length)
          ];
      } else {
        greeting =
          greetings.evening[
            Math.floor(Math.random() * greetings.evening.length)
          ];
      }

      return `${greeting}, ${username}`;
    },
    skipLogin() {
      this.test_visibility = !this.test_visibility;
    },
    signOut() {
      auth.signOut();
      this.$store.commit("setUser", null);
      this.$store.state.signout_visible = false;
      this.$store.state.books = [];
      this.$store.state.local_books = [];
      this.test_visibility = true;
    },
    async signInWithGoogle() {
      /*  if (this.$store.state.user) {
        console.log("already signed in");
        return;
      } */
      const provider = new GoogleAuthProvider();
      try {
        let result = await signInWithPopup(auth, provider);
        this.$store.commit("setUser", result.user);
        this.loadLibrary();
        await setDoc(
          doc(db, `users`, result.user.uid),
          {
            username: result.user.displayName,
            email: result.user.email,
            user_img_url: result.user.photoURL,
          },
          { merge: true }
        );
        this.test_visibility = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.greeting();
    if (auth.currentUser) {
      this.test_visibility = false;
      this.loadLibrary();
    }
  },

  computed: {
    username(): string {
      if (auth.currentUser?.displayName) {
        return auth.currentUser.displayName;
      } else {
        return "Guest";
      }
    },
    isLoggedIn(): boolean {
      console.log(this.$store.state.user ? true : false);
      return this.$store.state.user ? false : true;
    },
  },
});
