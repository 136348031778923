
import { defineComponent } from "vue";

import { db, auth } from "@/main";
import { doc, addDoc, collection, setDoc } from "@firebase/firestore";
import X2JS from "x2js";

export default defineComponent({
  props: {
    user_img_url: String,
  },
  data() {
    return {
      text: "" as any,
      book_file: null as any,
      book_file_name: null as any,
      isDragging: false,
      upload_to_cloud: false,
      error: null as any,
    };
  },
  methods: {
    dragover(e: any) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e: any) {
      e.preventDefault();
      //this.$refs.file.files = e.dataTransfer.files;
      this.processBook(e);
      this.isDragging = false;
    },

    browse() {
      let fileInput = document.getElementById(
        "book_upload"
      ) as HTMLInputElement;

      fileInput.click();
    },
    async processBook(ev: any) {
      if (
        ev &&
        ev.dataTransfer &&
        ev.dataTransfer.files &&
        ev.dataTransfer.files.length > 0
      ) {
        this.book_file = ev.dataTransfer.files[0];
        this.book_file_name = ev.dataTransfer.files[0].name;
        const reader = new FileReader();
        reader.onload = () => {
          this.text = reader.result;
        };
        reader.readAsText(this.book_file);
      } else {
        this.book_file = ev.target.files[0];
        this.book_file_name = ev.target.files[0].name;
        const reader = new FileReader();
        reader.onload = () => {
          this.text = reader.result;
        };
        reader.readAsText(this.book_file);
      }
    },
    clearBook() {
      this.book_file = null;
      this.book_file_name = null;
    },
    validateField(toValidate: any, field: string) {
      //I bet there is a better way to do this
      //but I don't care at this point
      if (!toValidate) {
        return "No " + field;
      }

      return toValidate;
    },

    openBook() {
      if (this.book_file && this.book_file_name && this.text) {
        this.saveBookToCloud();
        this.$store.state.adding_book = false;
      }
    },

    hashString(str: string) {
      let hash = 0;
      if (str.length == 0) return hash;
      for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },

    async saveBookToCloud() {
      if (!this.book_file || !this.book_file_name || !this.text) {
        return;
      }

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(this.text, "text/xml");

      let x2js = new X2JS();
      let book_object = (await x2js.xml2js(
        htmlDoc.documentElement.outerHTML
      )) as any;

      console.log(book_object);

      let book: any = {
        title: this.validateField(
          book_object.FictionBook.description["title-info"]["book-title"],
          "Title"
        ),
        author: this.validateField(
          book_object.FictionBook.description["title-info"]["author"],
          "Author"
        ),
        annotation: this.validateField(
          book_object.FictionBook.description["title-info"]["annotation"].p,
          "Annotation"
        ),
        genres: this.validateField(
          book_object.FictionBook.description["title-info"]["genre"],
          "Genres"
        ),
        progress: 0,
        total_chapters: 0,
        id: "",
      };

      
      if (book_object.FictionBook.binary[0]) {
        book.image = book_object.FictionBook.binary[0].__text;
        
      }else{
        book.image = book_object.FictionBook.binary.__text;
      }

      let chapters = [] as any;

      if (book_object.FictionBook.body.length) {
        //there are multiple sections with chapters
        book_object.FictionBook.body.forEach((block: any) => {
          if (block.section) {
            block.section.forEach((chapter: any) => {
              let parsed_chapter = this.parseAdditional(chapter);

              chapters.push(parsed_chapter);
            });
          }
        });
      } else {
        //there is only one section with chapters
        book_object.FictionBook.body.section.forEach((chapter: any) => {
          //iterate over all the p tags and add them to parsed p
          //if a p is not a string convert it to a string

          /*  let parsed_p = [] as any;

          chapter.p.forEach((p: any) => {
            parsed_p.push(JSON.stringify(p));
          });

          chapters.push({
            title: chapter.title,
            text: parsed_p,
          }); */
          let parsed_chapter = this.parseAdditional(chapter);

          chapters.push(parsed_chapter);
        });
      }

      book.total_chapters = chapters.length;

      if (!this.upload_to_cloud) {
        //hash title

        book.id = "local_" + this.hashString(book.title);

        //add chapters too book
        book.chapters = chapters;
        book.total_chapters = chapters.length;
        book.progress = 0;

        this.$store.commit("addLocalBook", book);

        this.$router.push(`/book/${book.id}`);
        return;
      }

      //save book to firestore

      const docRef = await addDoc(
        collection(db, `users/${auth.currentUser?.uid}/books`),
        book
      );

      //save chapters to firestore under chapter name
      chapters.forEach((chapter: any, index: number) => {
        setDoc(
          doc(
            db,
            `users/${auth.currentUser?.uid}/books/${docRef.id}/chapters`,
            `${index + 1}`
          ),
          chapter
        );
      });

      this.$router.push(`/book/${docRef.id}`);
    },

    parseAdditional(chapter: any) {
      /*  if (chapter.section || !chapter.p) {
        return {
          title: "Sooooo, there was more",
          text: "There was more text here but there is something strange going on with the format of this book. No more for you. Deal with it.",
        };
      } */

      //check if p is a string instead of an array

      try {
        let new_p = [] as any;

        chapter.p.forEach((p: any) => {
          if (p.emphasis) {
            new_p.push(`<i>${p.emphasis}</i>`);
            /* let emphasisIndex = 0; */
            //let temp_p = JSON.stringify(p).replace(/\n/g, function () {
            //check if the next emphasis is a string or an array
            //return p.emphasis[emphasisIndex++];
            //return `<emphasis >${p.emphasis}</emphasis>`;
            /* if (typeof p.emphasis == "string") {
                console.log(
                  `[AWESOME] <emphasis >${p.emphasis}</emphasis>`
                );

                return `<emphasis >${p.emphasis}</emphasis>`;
              } else {
                console.log(
                  `[AWESOME] <emphasis >${
                    p.emphasis[emphasisIndex++]
                  }</emphasis>`
                );
                return `<emphasis>${p.emphasis[emphasisIndex++]}</emphasis>`;
              } 

             
            });

            new_p.push(temp_p);
            });*/

            // new_p.push(temp_p);
          } else if (p.strong) {
            let p_text = p.toString();
            //if p.strong is an array
            if (Array.isArray(p.strong)) {
              p.strong.forEach((strong: any) => {
                p_text = p_text.replace(/\n/, `<b>${strong}</b>`);
              });
            }
            //if p.strong is a string
            else {
              p_text = p_text.replace(/\n/, `<b>${p.strong}</b>`);
            }

            new_p.push(p_text);
          } else {
            new_p.push(JSON.stringify(p));
          }
        });

        return { title: chapter.title, text: new_p };
      } catch (error) {
        console.log(`[parseAdditional] error`, error);

        return {
          title: { p: "Sooooo, there was more" },
          text: [
            "There was more text here but there is something strange going on with the format of this book. No more for you. Deal with it.",
          ],
        };
      }
    },
  },
  computed: {
    logged_in_and_cloud() {
      if (auth.currentUser && this.upload_to_cloud) {
        return true;
      } else {
        return false;
      }
    },
    logged_in() {
      if (auth.currentUser) return true;
      else return false;
    },
  },
});
