
import { defineComponent } from "vue";
import { auth } from "@/main";
export default defineComponent({
  props: ["book_chapters"],
  methods: {
    logout() {
      this.$store.state.user = null;
      auth.signOut();
    },
    selectChapter(chapter_index: number) {
      this.$emit("selectChapter", chapter_index);
      //scroll to top
      window.scrollTo(0, 0);
      this.$store.state.book_contents_visible = false;
    },
  },
  computed: {},
  setup() {
    return {};
  },
});
