
import { defineComponent } from "vue";

export default defineComponent({
  props: {


    title: {
      type: String,
      required: true,
    },
    author: {
      type: Object as () => {
        "first-name": string;
        "last-name": string;
      },
      required: true,
    },
    base64_img: {
      type: String,
      required: true,
    },
    book_id: {
      type: String,
      required: true,
    },
    total_chapters: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
  },
  methods: {
    base64ToImg(base64: string) {
      if (!base64) return "";
      return "data:image/png;base64," + base64;
    },
    openBook(book_id: string) {
      this.$router.push("/book/" + book_id);
    },

    getBookProgress() {
      if (this.total_chapters == 0) return 0;
      return Math.round((this.progress / this.total_chapters) * 100);
    },
  },
  computed: {
    is_local(){
      //get id from url
   
      if (this.book_id.startsWith("local_")) return true;
      return false;

    }
  },
  setup() {
    return {};
  },
});
